.counter h2 {
    font-size: 36px;
    color: #ffffff;
    text-align: center;
  }
  
  .counter {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .counter.active {
    opacity: 1;
    transform: translateY(0);
  }

  .glow {
    text-shadow: 0 0 2vw #d205bd;
  }

  .glow {
    animation: glow 1.2s ease infinite;
    -moz-animation: glow 1.2s ease infinite;
    -webkit-animation: glow 1.2s ease infinite;
  }
  