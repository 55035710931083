// Header.scss

.header {
    color: #fff;

    .logo {
        overflow: hidden;
        height: 60px;
    }

    nav {
        .nav-item {
            display: inline-block;
        }
    }

    ul {
        li {
            a {
                color: #ffffff;

                &:hover {
                    color: #d205bd;
                }
            }

            span {
                font-family: "montserrat-bold";
            }
        }
    }

}

.nav-item:last-child {
    margin-right: 0;
}

.page {
    background: url(../../assets/images/page-bg.jpg) fixed;
    background-size: cover;

    ul {
        list-style-type:  disc;
    }
}

.text-link {
    span {
        cursor: pointer;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        transition: 0.3s;

        &:hover {
            color: #d205bd;
            -webkit-transition: 0.3s;
            -moz-transition: 0.3s;
            transition: 0.3s;
        }
    }    
}

footer {
    background: #0c0c0c;

    ul {
        padding-left: 0;

        li {
            a {
                font-family: 'montserrat-regular';
                color: #ffffff;

                &:hover {
                    color: #d205bd;
                }
            }
        }
    }

    .col-title {
        font-family: 'montserrat-bold';

        i {
            font-size: 16px;
        }
    }

    p {
        i {
            font-size: 16px;
        }

        &.soc-med {
            a {
                width: 30px;
                height: 30px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .header {
        nav {
            top: 76px;
            left: 0;
            width: 100%;

            .nav-item {
                display: block;
            }
        }

        .sign-buttons {
            right: 16px;
        }
    }

    footer {    
        ul {
    
            li {
                margin-left: 8px;
                margin-right: 8px;
            }
        }
    }
}