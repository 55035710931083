@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'montserrat-bold';
  src: url('./assets/fonts/montserrat-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/montserrat-bold-webfont.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-regular';
  src: url('./assets/fonts/montserrat-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/montserrat-regular-webfont.woff') format('woff');
  font-style: normal;
}

body {
  background: #000000;
  margin: 0;
  font-family: 'montserrat-regular';
}

h1,h2,h3,h4,h5,h6,a,button,small,b {
  font-family: 'montserrat-bold';
}

ul {
  padding-left: 40px;
}

a,button {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;

  &:hover {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s;
  }

  i {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s;

    &:hover {
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      transition: 0.3s;
    }
  }
}

.bg-magenta:hover {
  background: #950086;
}
