/*#herosec {
    background: url(../../../assets/images/hero-img.png) bottom right;
    background-repeat: no-repeat;
    background-size: 65%;
}*/

@media (max-width: 1024px) {
    #herosec {
        background: none;
    }
}